@font-face {
  font-family: "Nuu";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/Nuu-Light.woff") format("woff");
}
@font-face {
  font-family: "Nuu";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/Nuu-Bold.woff") format("woff");
}

@charset "utf-8";

@import "./fonts.css";
@import "./bulma.scss";

:root {
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    --color-#{$name}: #{$color};
    --color-#{$name}-invert: #{$color-invert};

    @if length($pair) >= 4 {
      $color-light: nth($pair, 3);
      --color-#{$name}-light: #{$color-light};
      $color-dark: nth($pair, 4);
      --color-#{$name}-dark: #{$color-dark};
    }
  }

  --color-yellow-bright: hsl(59, 100%, 63%);

  @each $name, $shade in $shades {
    --color-#{$name}: #{$shade};
  }

  @each $name, $color in $schemeColors {
    --color-#{$name}: #{$color};
  }

  @each $name, $color in $componentColors {
    --color-#{$name}: #{$color};
  }

  --radius-small: #{$radius-small};
  --radius: #{$radius};
  --radius-large: #{$radius-large};
  --radius-rounded: #{$radius-rounded};
}

html {
  @include until($tablet) {
    //font-size: 12px;
  }
  // font-size: 14px;
  // @include tablet {
  //   font-size: 16px;
  // }
  // @include desktop {
  //   font-size: 20px;
  // }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table {
  border-collapse: unset; // Otherwise Chrome will have border rendering bugs
  border-bottom-width: 0 !important;
}

#root {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto auto 1fr;
}

.footer {
  margin-top: auto;
}

.section {
  @include until($tablet) {
    // padding: 3rem 0.75rem;
  }
}

.content-container {
  margin: 0 auto;
  width: auto;
  max-width: 1152px;
}

.subtitle {
  line-height: 1.5;
}

table {
  font-size: 0.875rem;
  border-bottom: solid 1px $grey-lighter;

  th:first-child,
  td:first-child {
    padding-left: 0.75em;
  }
  th:last-child,
  td:last-child {
    padding-right: 0.75em;
  }
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-space-pre-line {
  white-space: pre-line;
}

a.has-cta {
  &:focus,
  &:active,
  &:hover,
  &:focus-within {
    > .box.has-cta {
      background-color: $grey-lightest;
    }
    > .box.cta-box {
      &:after {
        opacity: 0.15;
      }

      &.cta-box-2 {
        &:after {
          opacity: 0.3;
        }
      }
    }
  }
}

a {
  &.is-inverted {
    color: $link-hover;

    &:focus,
    &:active,
    &:hover,
    &:focus-within {
      color: $link;

      &.is-primary {
        color: $primary;
      }
    }
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.box {
  &.is-fullheight {
    height: 100%;
  }

  &.has-cta {
    &:focus,
    &:active,
    &:hover,
    &:focus-within {
      background-color: $white-bis;
    }
  }

  &.cta-box {
    position: relative;
    z-index: -1;

    &:after {
      content: " ";
      border-radius: $radius-large;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -2;
      background-color: $black;

      transition: opacity 250ms;
    }

    &.cta-box-0 {
      background-color: #6f73dc;
      color: white;
    }
    &.cta-box-1 {
      background-color: $primary;
      color: bulmaLighten($primary-light, 12);
    }
    &.cta-box-2 {
      background-color: $primary-dark;
      color: bulmaLighten($primary-dark, 63);
    }
  }
}

.panel {
  border: 1px solid bulmaLighten($grey-lighter, 6);
}

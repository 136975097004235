@import "bulma/sass/utilities/functions.sass";

$green: hsl(145, 50%, 62%) !default;
$turquoise: hsl(241, 100%, 71%) !default;
$blue: hsl(241, 59%, 56%) !default;
$red: hsl(0, 84%, 71%) !default;
$yellow: hsl(36, 100%, 56%) !default;

$size-7: 0.875rem;
$size-8: 0.75rem;
$size-9: 0.5rem;

$size-small: $size-8 !default;

@import "bulma/sass/utilities/initial-variables.sass";

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 $size-8 $size-9 !default;

$primary: $blue !default;
$primary-light: hsla(240, 72%, 82%, 1) !default;
$primary-dark: hsl(240, 58%, 28%) !default;

$link: $primary !default; // We want links to have the same blue color as our primary color

$success: $green !default;
$success-light: bulmaLighten($success, 25) !default;

$scheme-main: #f8f8f8 !default;
$scheme-main-bis: $white-ter !default;
$scheme-main-ter: $grey-lightest !default;

$text: $black !default;
$text-invert: findColorInvert($text) !default;
$text-light: $grey !default;
$text-strong: $black !default;

$body-family: "Nuu", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$navbar-background-color: transparent !default;
$navbar-item-hover-background-color: transparent !default;
$navbar-item-hover-color: $text !default;
$navbar-box-shadow-size: 0 2px 2px 0 !default;
$navbar-box-shadow-color: rgba(0, 0, 0, 0.15) !default;

$input-background-color: transparent !default;

$pagination-border-color: transparent !default;
$pagination-current-color: $text-strong !default;
$pagination-current-border-color: $grey-lightest !default;
$pagination-current-background-color: $grey-lightest !default;

// $table-cell-padding: 1.35em 1em 0.875em 1em !default;
$table-cell-padding: 1.25em 1em 0.75em 1em !default;
//$table-cell-padding: 0.9em 0.75em 0.5em 0.75em !default;
$table-row-hover-background-color: $scheme-main-ter !default;

//$body-size: 14px;

//$box-shadow: 0 0.275em 0.2em 0em $grey-light, 0 0px 0 1px $white-ter !default;
$box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15) !default;

$box-background-color: $white !default;

//$card-shadow: 0 0.275em 0.2em 0em $grey-light, 0 0px 0 1px $white-ter !default;
$card-shadow: $box-shadow;

$panel-item-border: 2px solid $grey-lighter !default;

$footer-background-color: $scheme-main !default;

$modal-content-width: 900px !default;
$modal-breakpoint: $desktop !default;

@import "bulma/bulma.sass";

$schemeColors: (
  "scheme-main": $scheme-main,
  "scheme-main-bis": $scheme-main-bis,
  "scheme-main-ter": $scheme-main-ter,
  "scheme-invert": $scheme-invert,
  "scheme-invert-bis": $scheme-invert-bis,
  "scheme-invert-ter": $scheme-invert-ter,
) !default;

$componentColors: (
  "table-row-hover-background": $table-row-hover-background-color,
) !default;
